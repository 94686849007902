<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-8">IP Adresi Listesi</div>
            <div class="col-4 text-right">
              <CButton color="success" square
                       size="sm"
                       @click="openModalEvent('create','ipForm', null, 'Yeni IP Ekle')"
              >
                Kayıt Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="ipWhiteList"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            :loading="loading"
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update','ipForm', lastItem, 'IP Düzenleme Ekranı ('+ lastItem.description+')')"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('update','ipForm', item, 'IP Düzenleme Ekranı ('+ item.description+')')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('destroy','confirm', item, 'Kayıt Sil', item.description+' isimli IP adresini silmek istediğinize emin misiniz?')">
                    Kayıt Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #blockFlag="{item}">
              <td>
                <CIcon
                  v-if="item.blockFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />

  </CRow>
</template>
<script>
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'

export default {
  name: 'IPWhiteList',
  components: {
     FormModal, ConfirmModal
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'admin',
      form: 'ipForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      action: '',
      lastItem: {},
      selectedPages: [],
      ipwhitelist: [],
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'blockFlag', label: 'Blok' },
        {key: 'description', label: 'Açıklama' },
        {key: 'ipAddress', label: 'IP Adresi'},
       
      ],
      activePage: 1,
    }
  },
  computed: {
    ipWhiteList(){
      return this.$store.getters.ipWhiteList;
    },
    loading(){
      return this.$store.getters.adminLoading;
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.confirmSize = 'sm'
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    rowClicked(item, index) {
      this.lastItem = item;
    },

    async getIPWhiteList() {
      await this.$store.dispatch('ipwhite_list');
    },

    async crud(item, actionType) {

      var formData = new FormData();

      formData.append('actionType', actionType);

      if(item.blockFlag){
        formData.append('blockFlag',  item.blockFlag);
      }
      if(item.ipAddress) {
        formData.append('ipAddress',  item.ipAddress);
      }
      if (actionType !== 'destroy') {
        formData.append('description', item.description);
      }
      if (actionType !== 'create') {
        formData.append('id', item.id);
      }

      await this.$store.dispatch('ipwhite_action', formData)

      if (this.$store.getters.adminStatus.success) {
        this.openModalEvent();
        this.getIPWhiteList();
      }
    },
  },
  created() {
    this.getIPWhiteList();
  }
}
</script>
